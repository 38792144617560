const firebaseConfig = {
  apiKey: "AIzaSyC99SwUI_7g5vEpQ38DAwtoJ5jAqU2lT3U",
  authDomain: "1gie-login.globe.com.ph",
  databaseURL: "https://globe-isg-onegie-prodv2.firebaseio.com",
  projectId: "globe-isg-onegie-prodv2",
  storageBucket: "globe-isg-onegie-prodv2.appspot.com",
  messagingSenderId: "459455889031",
  appId: "1:459455889031:web:9789d467c1e97da73ac222",
};

export default firebaseConfig;
