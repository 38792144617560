import { GoogleAuthProvider } from "firebase/auth";

import * as firebaseui from "firebaseui";

import * as ciap from "gcip-iap";

import firebaseConfig from "./firebaseConfig";

const configs = {
  [firebaseConfig.apiKey]: {
    authDomain: firebaseConfig.authDomain,
    tenants: {
      _: {
        signInOptions: [
          {
            provider: GoogleAuthProvider.PROVIDER_ID,
            scopes: ["https://www.googleapis.com/auth/userinfo.profile"],
            providerName: " ",
            fullLabel: "Log in using your Google account",
            displayName: "1GIE",
            buttonColor: "#003490",
            displayMode: "optionFirst",
          },
        ],
      },
    },
  },
};

export const startFirebase = () => {
  const handler = new firebaseui.auth.FirebaseUiHandler(
    "#firebaseui-auth-container",
    configs
  );

  const ciapInstance = new ciap.Authentication(handler);
  ciapInstance.start();
};
