import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { startFirebase } from "./firebase.js";

import "./App.css";

import loginBack from "./assets/loginBack_2x.png";
import globeLogo from "./assets/globe_logo.png";

const smaltBlue = "#003490";

const App = ({ className }) => {
  const isIframe = window.self !== window.top;

  useEffect(() => {
    startFirebase();
  }, []);
  return (
    <div
      className={`${className} ${
        isIframe ? "hide" : ""
      } box-container items-center`}
    >
      <div className="box-container container pt-6 px-[15px]">
        <img className="logo" src={globeLogo} alt="" />
        <div className="box-container content pt-[53px] items-center">
          <div className="box-container">
            <span className="-one">One</span>
            <span className="-gie">Globe Information Exchange</span>

            <div id="firebaseui-auth-container"></div>
          </div>
          <div className="box-container disclaimer">
            <p>
              <span className="disclaimer-title">DISCLAIMER: </span>
              ONLY AUTHORIZED USERS ARE ALLOWED TO ACCESS THIS SYSTEM
            </p>
            <p>
              The programs and data stored in this system are licensed, private
              property of Globe Telecom. They are made available only to
              authorized users. All login attempts and access are recorded and
              verified.
            </p>
            <p>If you are not an authorized user, DO NOT ATTEMPT TO LOGIN.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Follow the styles from ui/src/LoginStyle.js
export default styled(App)`
  width: 100vw;
  height: 100vh;
  background: url(${loginBack}) no-repeat;
  background-position: 50% 0;
  background-size: cover;

  &.hide {
    display: none;
  }

  .container {
    max-width: 1140px;
    width: 100%;
    height: 100%;
  }

  .logo {
    background: transparent;
    margin-top: 20px;
    margin-left: auto;
    width: 125px;
    height: 47px;
  }

  .content {
    height: 100%;
    line-height: 1.5;
  }

  .-one {
    align-self: flex-start;
    border-bottom: #ffffff solid 6px;
    color: white;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 16px;
    width: 130px;
  }

  .-gie {
    color: white;
    font-size: 32px;
  }

  .login-btn {
    background-color: ${smaltBlue};
    color: white;
    margin-top: 40px;
    border: none;
  }

  .disclaimer {
    margin-top: auto;
    color: white;
    font-size: 10px;
    width: 472px;
    padding-bottom: 1rem;
  }

  .disclaimer p {
    margin: 0;
  }

  .disclaimer-title {
    font-weight: bold;
  }

  @media only screen and (max-width: 480px) {
    .logo {
      margin-top: 0;
    }
    .container {
      padding-top: 12px;
    }
    .disclaimer {
      padding-bottom: 4.375rem;
    }
  }
`;
